
import { computed, defineComponent, onBeforeMount, reactive, ref } from "vue";

import { add, trashBin } from "ionicons/icons";

import {
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonDatetime,
  IonRow,
  IonIcon,
  IonChip,
  IonButtons,
  IonButton,
  IonPage,
  IonCardTitle,
} from "@ionic/vue";

import SetupModuleHeader from "./SetupModuleHeader.vue";
import SetupDoneButton from "./SetupDoneButton.vue";

import LanguageService from "../../../lib/service/LanguageService";
import CreateService from "../../../lib/service/CreateService";
import DateFormatter from "../../../lib/formatting/DateFormatter";
import SetupTitleInput from "./SetupTitleInput.vue";
import { DateInfo } from "../../../../../shared/protocol/Date";
import { ValidationResult } from "../../../lib/form/Validation";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "SetupDate",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonToggle,
    IonContent,
    IonCard,
    IonCardContent,
    IonDatetime,
    IonCardHeader,
    IonRow,
    IonIcon,
    IonChip,
    IonButtons,
    IonButton,
    IonPage,
    IonCardTitle,
    SetupModuleHeader,
    SetupDoneButton,
    SetupTitleInput,
  },
  setup() {
    const lang = LanguageService.getTranslator("module/date");
    const langGeneric = LanguageService.getTranslator("generic");
    const withTime = ref(false);
    const dateInput = reactive({
      date: "",
      time: "",
    });
    const router = useRouter();
    const currentYear = `${new Date().getFullYear()}`;
    const maxYear = `${new Date().getFullYear() + 10}`;

    const dateInputValid = computed(() => {
      return (
        dateInput.date.length > 0 &&
        CreateService.info.config.date !== undefined &&
        CreateService.info.config.date.dates.length < 50 &&
        (!withTime.value || dateInput.time.length > 0)
      );
    });

    const validator = computed<ValidationResult>(() => {
      CreateService.configState.date = false;
      if (CreateService.info.config.date) {
        if (CreateService.info.config.date.title.length <= 2) {
          return {
            valid: false,
            message: "titleTooShort",
          };
        }
        if (CreateService.info.config.date.dates.length >= 2) {
          CreateService.configState.date = true;
          return {
            valid: true,
          };
        } else {
          return {
            valid: false,
            message: "tooFewDates",
          };
        }
      }
      return {
        valid: false,
      };
    });

    const addDate = () => {
      if (
        CreateService.info.config.date !== undefined &&
        CreateService.info.config.date.dates.length < 50
      ) {
        const date = new Date(dateInput.date);
        const dateInfo: DateInfo = {
          weekday: date.getDay(),
          formatted: date.toLocaleDateString(),
          timestamp: date.getTime() / 60000,
        };
        if (withTime.value) {
          const time = new Date(dateInput.time);
          dateInfo.time = DateFormatter.formatTime(time);
        }
        CreateService.info.config.date.dates.push(dateInfo);
      }
    };

    const removeDate = (index: number) => {
      if (CreateService.info.config.date !== undefined) {
        CreateService.info.config.date.dates.splice(index, 1);
      }
    };

    onBeforeMount(() => {
      if (CreateService.info.config.date === undefined) {
        CreateService.info.config.date = {
          title: lang.t("title"),
          dates: new Array<DateInfo>(),
        };
        CreateService.configState.date = false;
      }
      if (!CreateService.checkGuard("setup")) {
        router.push("/create");
      }
    });

    return {
      lang,
      langGeneric,
      withTime,
      dateInput,
      CreateService,
      DateFormatter,
      LanguageService,
      add,
      trashBin,
      addDate,
      removeDate,
      validator,
      dateInputValid,
      currentYear,
      maxYear
    };
  },
});
