import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "small-content setup-page" }
const _hoisted_2 = { class: "medium-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_setup_module_header = _resolveComponent("setup-module-header")!
  const _component_setup_title_input = _resolveComponent("setup-title-input")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_setup_done_button = _resolveComponent("setup-done-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_setup_module_header, {
        title: _ctx.lang.t('title')
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_setup_title_input, {
                label: _ctx.lang.t('titleLabel'),
                module: "date"
              }, null, 8, ["label"]),
              _createVNode(_component_ion_card, { class: "h-fit" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.lang.t("addDate")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_card_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_list, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.lang.t("withTime")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_toggle, {
                                modelValue: _ctx.withTime,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.withTime) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.lang.t("date")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_datetime, {
                                displayFormat: _ctx.LanguageService.ionicDateFormat,
                                min: _ctx.currentYear,
                                max: _ctx.maxYear,
                                modelValue: _ctx.dateInput.date,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dateInput.date) = $event))
                              }, null, 8, ["displayFormat", "min", "max", "modelValue"])
                            ]),
                            _: 1
                          }),
                          (_ctx.withTime)
                            ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.lang.t("time")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_datetime, {
                                    displayFormat: _ctx.LanguageService.timeFormat,
                                    modelValue: _ctx.dateInput.time,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dateInput.time) = $event))
                                  }, null, 8, ["displayFormat", "modelValue"])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, { class: "ion-justify-content-end" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            size: "small",
                            disabled: !_ctx.dateInputValid,
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addDate()))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"]),
                              _createTextVNode(" " + _toDisplayString(_ctx.langGeneric.t("add")), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled"])
                        ]),
                        _: 1
                      }),
                      (_ctx.CreateService.info.config.date.dates.length <= 0)
                        ? (_openBlock(), _createBlock(_component_ion_chip, { key: 0 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.lang.t("noDates")), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.CreateService.info.config.date.dates, (date, index) => {
                                return (_openBlock(), _createBlock(_component_ion_item, { key: index }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(date.formatted) + " ", 1),
                                        (date.time)
                                          ? (_openBlock(), _createBlock(_component_ion_chip, { key: 0 }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(date.time), 1)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_ion_buttons, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_button, {
                                          onClick: ($event: any) => (_ctx.removeDate(index)),
                                          color: "danger"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, { icon: _ctx.trashBin }, null, 8, ["icon"])
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          })),
                      _createVNode(_component_setup_done_button, {
                        disabled: !_ctx.validator.valid,
                        error: _ctx.lang.t(_ctx.validator.message)
                      }, null, 8, ["disabled", "error"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}